import React, { useState, } from "react";
import "./App.css";
import {
    Page, Toolbar,Carousel, CarouselItem,
} from "react-onsenui";
import sunnyNips from "./nips/sunny_nipples.png";
import darkNipples from "./nips/dark_nipples.png";
import cuteyBoy from "./nips/cutey_boy.PNG";

const App = () => {
    const [index, setIndex] = useState(0);
    const [pictures] = useState([sunnyNips, darkNipples, cuteyBoy]);

    const handleChange = (event) => {
        setIndex(event.activeIndex);
    };

    const setNewIndex = (newIndex) => {
        setIndex(newIndex);
    };

    const renderToolbar = () => {
        return (
            <Toolbar>
                <div className="center">Austin has sexy nipples</div>
            </Toolbar>
        );
    };

    return (
        <Page renderToolbar={renderToolbar}>
            <div
                style={{
                    textAlign: "center",
                    padding: "10px",
                }}
            >
                Swipe for more nippy pics!
            </div>
            <Carousel
                index={index}
                onPostChange={handleChange}
                swipeable
                autoScroll
                overscrollable
                centered
                style={{
                    height: "300px",
                    width: "200px",
                    margin: "0 auto",
                }}
            >
                {
                    pictures.map((picture, i) => {
                        return (
                            <CarouselItem key={i}>
                                <img
                                    src={picture}
                                    alt="nipple_pic"
                                    height="300px"
                                    width="200px"
                                />
                            </CarouselItem>
                        );
                    })
                }
            </Carousel>
            
            <div
                style={{
                    textAlign: "center",
                    fontSize: "20px",
                }}
            >
                {pictures.map((picture, i) => (
                    <span key={i} style={{cursor: "pointer"}} onClick={() => {
                        setNewIndex(i);
                    }}>
                        {index === i ? "\u25CF" : "\u25CB"}
                    </span>
                ))}
            </div>
        </Page>
    );
}

export default App;
